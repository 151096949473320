.workSpace{
   height: 100%;
   overflow: hidden;
   display: grid;
   grid-template-rows: minmax(32px,32px) minmax(0,20fr);
   grid-template-columns: 1fr;
}

.containerForPopup{
  position: absolute;
  bottom: 0px; left: 0px; top: 0px; right: 0px;
  width: 1px;;
  height: 1px;
  margin: auto;
}