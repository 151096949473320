.C_SaP, .C_Ns_S, .C_G_S, .C_D, .C_Subs, .C_E, .C_Unsub, .O, .C_G_Cont{
  display: flex;
  flex-direction: row;
}

.C_G_S{
  margin-left: -30px;
  margin-right: -30px;
}

.C_E{
  margin: 0 0%;
}

.C_E div+div{
  color: #466289 !important;

}

.O div{
  font-weight: bold;
}

.C_SaP div, .C_G_S div, .C_D div, .C_Subs div, .C_E div, .C_Unsub div, .O div, .C_G_Cont div, .C_Ns_S div{
  margin-top: 20px;
  flex: 1;
  font-size: 12px;
  font-family: Roboto;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.0357143em;
  text-align: center;
  color: #1D1D1D;
  cursor: pointer;
}

.C_Ns_S div, .C_Ns_S div:last-child, .C_G_S div:last-child, .C_G_Cont div:last-child{
  margin-top: 20px;
  flex: 1;
  font-size: 12px;
  font-family: Roboto;
  line-height: 14px;
  letter-spacing: 0.0357143em;
  color: #1D1D1D;
}

.C_Ns_S div + div, .C_G_S div + div, .C_Unsub div:last-child, .C_G_Cont div + div{
  font-weight: bold;
  color: #A52F2F;
}

.C_G_S div{
  flex: 0.5;
}

.C_G_S div + div{
  flex: 1.5;
}

.C_Subs div:last-child, .C_E div:last-child{
  font-weight: bold;
  color: #3B4F6A;
}

.C_D div:last-child{
  color: #A52F2F;
  font-weight: bold;
}

.C_G_Cont div:first-child{
  text-align: left;
}

.C_G_Cont div:last-child{
  text-align: right;
}

.C_G_Cont div + div{
  margin-left: -20px;
  text-align: left;
}