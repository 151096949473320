.smallError {
  width: auto;
  float: left;
  position: absolute;
  font-size: 13px;
  color: #BD3131;
  /* text-align: left; */
  line-height: 34px;
  margin-left: 17px;
  white-space: nowrap;
}

.smallErrorAuth {
  color: #ff4343 !important;
}

.unknownError{
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3 !important;
  display: block;
  margin-top: 44px;
  font-size: 18px;
  line-height: 21px;
  color: #ff4343;
}
.successType{
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3 !important;
  display: block;
  margin-top: 44px;
  font-size: 18px;
  line-height: 21px;
  color: rgb(255, 255, 255);
}