.popupContainer {
  position: absolute;
  min-width: 232px;
  min-height: 100px;
  background-color: #ffffff;
  border-radius: 5px 5px 5px 5px;
  border: 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 3px 11px 22px rgba(59, 59, 59, 0.25);
  z-index: 101;
}

.popupContainer div.head {
  width: 100%;
  height: 6px;
  border-radius: 5px 5px 0px 0px;
}

.popupContainer div.body {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-width: 0px;
  border-bottom-width: 0px;
}

.popupContainer div.bottom {
  height: 6px;
  border-radius: 0px 0px 5px 5px;
  border-top-width: 0px;
}

#bgPopup{
  width: 100%;
  height: 100%;
  background-color: #597294;
  opacity: 0.7;
  position: absolute;
  left: 0px;
  top: 0px;
}

.containerForPopup{
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}