.current{
  background-color: rgba(89, 114, 148, 0.12);
}

.notCurrent{
  background-color: #ffffff;
}

.brainImg{
  float: left;
  height: 20px;
  line-height: 20px;
}

.brainText{
  margin-left: 4px;
  height: 20px;
  line-height: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  color: #7B7B7B;
  float: left;
}

.options{
  float: left;
  width: 10%;
  height: 100%;
  min-height: 38px;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.options div:first-child{
  flex: 3;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.options div:last-child{
  flex: 1;
  width: 8px;
}

.experimental{
  height: 1px;
  background-color:rgba(89, 114, 148, 0.12);;
  margin: 4px 8px 0px 8px;
}

.notFoundText {
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #929292;
  width: 200px;
  margin: 0 auto;
  margin-top: 20px;
}

.noReportsText{
  font-size: 13px;
  text-align: left;
}

.reportsLoader{
  flex:1;
  height: 75px;
}
.newReportContainer{
  animation: opacity 300ms ease-in-out;
}

@keyframes opacity {
  from { opacity : 0; }
  to { opacity :1; }
}