  .loader {
    margin: 0 auto;
    margin-top: 20px;
    display: none;
  }
  .dot {
    width: 12px;
    height: 12px;
    background: #3ac;
    border-radius: 100%;
    display: inline-block;
    animation: slide 1s infinite;
  }
  .dot:nth-child(1) {
    animation-delay: 0.1s;
    background: #334C60;
  }
  .dot:nth-child(2) {
    animation-delay: 0.2s;
    background: #334C60;
  }
  .dot:nth-child(3) {
    animation-delay: 0.3s;
    background: #334C60;
  }
  .dot:nth-child(4) {
    animation-delay: 0.4s;
    background: #334C60;
  }
  .dot:nth-child(5) {
    animation-delay: 0.5s;
    background: #334C60;
  }
  @-moz-keyframes slide {
    0% {
      transform: scale(1);
    }
    50% {
      opacity: 0.3;
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
  }
  @-webkit-keyframes slide {
    0% {
      transform: scale(1);
    }
    50% {
      opacity: 0.3;
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
  }
  @-o-keyframes slide {
    0% {
      transform: scale(1);
    }
    50% {
      opacity: 0.3;
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes slide {
    0% {
      transform: scale(1);
    }
    50% {
      opacity: 0.3;
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
  }

  .preloaderText{
      font-family: Roboto;
      text-align: center;
      color: rgb(28, 37, 43);
  }