.workPanel{
  position: relative;
  display: grid;
  justify-items: stretch;
  grid-gap: 10px 10px;
  grid-template-columns: [leftMenu] minmax(240px,240px) [rightPanel] minmax(0,1fr)/* 3.239fr */;
  padding: 10px 8px 8px;
}

.messageText{
  justify-self: center;
  align-self: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #a7a7a7;
}

.modWorkPanel{
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  color: #A6A6A6;
}

.modWorkPanel span{
  text-decoration-line: underline;
  color: #456883;
  cursor: pointer;
}

.mainPanelHelp {
  align-self: center;
  background-image: url("/img/mainPanelHelp.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}