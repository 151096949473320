/* Стиль контейнера редактора репорта */
.reportEditorContainer {
  box-sizing: border-box;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(107, 107, 107, 0.1);
  border-radius: 3px;
  overflow-y: scroll;
  display: grid;
  grid-template-rows:
    [topHeader] minmax(20px, 20px) [reportDataHeader] minmax(32px, max-content)
    [categoryList] minmax(0, 1fr);
}

/* Заголовок */
.topHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dde5ea;
  border-bottom: 1px solid #d8d8d8;
}
.topHeader span {
  /*color: #fff;*/
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.03em;
}
/* Для работы с данными репорта (дата, фио и т.п. */
.reportDataHeader {
  padding: 10px 0 12px 0;
  overflow-x: auto;
  background: #f0f4f7;
  border-bottom: 1px solid #e3e3e3;
  display: flex;
}
.reportDataHeader > div{
  flex:1;
}
.reportDataHeader > div:not(:first-child){
  margin-left: 35px;
}
.reportDataHeader > div:first-child{
  margin-left: 20px;
}
.reportDataHeader > div:nth-child(odd){
  max-width: 120px;
}
.reportDataHeader > div:nth-child(even){
  max-width: 230px;
}
/*  */
.editorArea {
  background: #ecf1f5;
  height: fit-content;
  padding: 17px 0 17px 0;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: [controlPanel] minmax(0,1fr) [others] 0px;
}

/* Стили области управления редактором  */
.controlPanel {
  border-left: 0.5px solid #cdcdcd;
  display: flex;
  flex-flow: column nowrap;
  /* display: grid;
  grid-template-rows: [openall] max-content [selectorarea] minmax(0, 1fr) [buttons] 80px; */
}

.controlPanel > div:nth-child(1) {
  flex:1 1 10px;
  max-height: 10px;
  display: flex;
  padding: 0 0 10px 18px;
  align-items: center;
}

.customCheckbox {
  /* Стили для чекбокса */
  cursor: pointer;
  user-select: none;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.0357143em;
}

.customCheckbox:checked + label {
  color: green !important;
}
.box {
  width: 20px;
  height: 20px;
  content: "";
  background-color: rebeccapurple;
}
.customCheckbox input:checked + box {
  width: 20px;
  height: 20px;
  content: "ddd";
  background-color: green;
}

/* Стили блока селектора */
.blockContainer{
  overflow-y: auto;
  flex:4 4 40px;
  min-height: 30px;
}
.blockSelector {
  padding: 5px 0 5px 0;
  margin: 0 12px 0 12px;
  align-items: stretch;
  display: flex;
  justify-items: stretch;
  flex-flow: column nowrap;
  border-radius: 3px;
  height: min-content;
  transition: background-color 0.2s ease-in-out;
}

.blockSelector > div {
  border-radius: 3px;
  position: relative;
  justify-content: center;
  min-height: 20px;
  height: 24px;
  margin: 1px 0 0 0;
  padding: 2px 0px 2px 8px;
  flex-flow: column nowrap;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
}
.blockSelector > div:last-child div {
  position: absolute;
  top: -6px;
  right: 0;
  justify-self: flex-start !important;
  width: 98%;
  border-top: 1px solid #d1d1d1;
}
.blockSelector > div:last-child {
  margin-top: 12px;
}
.blockSelector span {
  align-items: center;
  width: 100%;
  align-self: center !important;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.0357143em;
  color: #000000;
}
.activeCategory {
  background-color: rgba(89, 114, 148, 0.12);
  border-radius: 3px;
  height: max-content;
  transition: background-color 0.2s ease-in-out;
}

/* Блок с кнопками */
.buttonsBlock {
  flex:1 1 106px;
  max-height: 150px;
  max-width: 240px;
  padding: 38px 6px 0 14px;
  display: grid;
  grid-template-rows: repeat(auto-fill, max-content);
}
.buttonsBlock .disableButton {
  background-color: none !important; 
  cursor: default;
}
.buttonsBlock > div {
  min-height: 38px;
  display: flex;
  user-select: none;
  cursor: pointer;
  padding-left: 10px;
}

.buttonsBlock > div:nth-child(1) {
  background: #d1dae4;
  border-radius: 3px;
}

.buttonsBlock > div:hover {
  background-color: #bcc5d4;
  border-radius: 3px;
}
.buttonsBlock > div > div:nth-child(1) {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonsBlock > div > div:nth-child(2) {
  flex: 9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.buttonsBlock span {
  justify-self: flex-start;
  font-size: 13px;
  line-height: 15px;
  padding-left: 6px;
  color: #000000;
}

.customCheckbox {
  position: absolute;
  opacity: 0;
}

.customCheckbox + span {
  user-select: none;
  position: relative;
  cursor: pointer;
  font-size: 13px;
  /*letter-spacing: 0.0357143em;*/
  color: #000000;
}

.customCheckbox + span:before {
  content: url("../images/checkbox.svg");
  width: 13px;
  vertical-align: middle;
  height: 14px;
  box-sizing: border-box;
  margin-right: 7px;
  display: inline-block;
}

.customCheckbox:checked + span:before {
  content: url("../images/checkboxSelected.svg");
}
.test {
  background: #fff;
  border-radius: 4px;
  display: flex;
  padding: 20px 0px 0 15px;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-items: stretch;
  width: max-content;
  width: 99%;
  margin: 0px 0px 14px 0;
  height: max-content;
}

.test > div {
  position: relative;
  flex: 1 1 auto;
  margin-top: 14px;
  min-height: 0px;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 6px;
  height: max-content;
}

.objectsContainer > form > label {
  padding: 2px 0 0 0px;
}

.paragraphSeparator {
height: 2px;
width: 100px;
background: #aaa;
}

.simpleTextArea {
  margin-top: 20px !important;
  background: #fff;
}

.test textarea, .simpleTextArea {
  outline: none;
  white-space: pre-wrap;
  margin-top: 9px;
  padding: 9px;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 300px;
  max-height: 100%;
  height: max-content;
  min-width: 400px;
  width: 750px;
  resize: both;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.reportTextContainer {
  background: #eaeef2;
  border-radius: 4px;
  max-width: 1020px;
}

.reportTextContainer > div {
  margin-top: 0 !important;
}

.categoryHeader {
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #464646;
}

.categoryHeaderFastInsert {
  color: #fff;
}

.categoryHeaderForReport {
  border-left: none;
  padding: 4px !important;
  font-size: 23px;
  letter-spacing: 0.03em;
  }

.reportTextHeader {
  position: absolute;
  left: 334px;
  top: 13px;
}

.editorParts {
  margin: 0 17px 0 17px;
  height: max-content;
  width: auto;
  display: flex;
  align-items: stretch;
  /*overflow-x: auto;*/
}

.editorParts > div {
  display: flex;
  flex-flow: column nowrap;
  padding: 0 0 14px 0;
  width: 100%;
}

.imageUploadButtons {
  margin-top: 20px;
  height: 27px;
  width: 151px;
  background: #f4f4f4;
  border-radius: 3px;
  user-select: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.imageUploadButtons:hover {
  background-color: rgba(89, 114, 148, 0.12);
}
.imageUploadButtons svg {
  flex: 1;
}
.imageUploadButtons span {
  flex: 4;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: #000000;
}

.imagesContainer {
  margin-top: 12px;
  padding-right: 5px;
}

.imageContainer:not(:first-child) {
  margin-top: 11px;
}

#selecterImg {
  display: none;
}

.mainImagesContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.imageContainer {
  position: relative;
  background-color: none;
  cursor: pointer;
}

.imageContainer div {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  right: 0px;
  margin: 6px 6px;
  cursor: pointer;
}
.imageContainer:hover div {
  opacity: 1;
}

.subColumn > span {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 700;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0.0357143em;
  color: #5d6c73;
}

.subColumn {
  margin: 0px 14px 18px 0px;
  padding: 4px 14px 10px 12px;
  height: max-content;
  width: 180px;
  min-width: 100px;
  border: 2px solid #ececec;
  border-radius: 4px;
  background: #fefefe;
}

.subColumn p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.0357143em;
}

form {
  display: flex;
  flex-flow: column nowrap;
}
.customRadio {
  display: none;
}
.customRadio + span {
  user-select: none;
  position: relative;
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  /*letter-spacing: 0.0357143em;*/
}
.customRadio + span:before {
  content: url("../images/radiobutton.svg");
  width: 14px;
  vertical-align: middle;
  height: 14px;
  box-sizing: border-box;
  margin-right: 7px;
  display: inline-block;
}
.customRadio:checked + span:before {
  content: url("../images/radiobuttonselected.svg");
}

.inpText span {
  margin-top: 8px;
  font-size: 13px;
  line-height: 11px;
  text-align: center;
  /*letter-spacing: 0.0357143em;*/
}
.inpText input {
  text-align: center;
  margin: 0 5px 0 5px;
  outline: none;
  width: 40px;
  min-width: 40px;
  max-width: 350px;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border: 0.5px solid #bbbbbb;
  background: none;
}
.AoDButton {
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid;
  border-right: 4px solid;
  height: 22px;
  width: 80px;
  box-sizing: border-box;
  border-radius: 1px;
  text-align: center;
  line-height: 11px;
  transition: 0.1s ease-in-out;
}

.AoDButton span:nth-child(1) {
  flex: 3;
  font-size: 11px;
}
.AoDButton span::first-letter {
  font-weight: 900;
}
.AoDButton.Add {
  margin-top: 10px;
  color: #6fcf97;
  border-color: #6fcf97;
}
.AoDButton.Add:hover {
  color: #fff;
  background-color: #6fcf97;
}
.AoDButton.Delete:hover {
  color: #fff;
  background-color: #eb5757;
}

.AoDButton.Delete {
  margin-top: 7px;
  margin-bottom: 9px;
  color: #eb5757;
  border-color: #eb5757;
}
.objectsContainer {
  margin-left: 0px;
  margin-top: 6px;
}
.objectsContainer form:not(:first-child) {
  margin-top: 10px;
}
.bottomBorder {
  border-bottom: 0.5px solid #e8e8e8;
}

.customSelect select {
  font-family: Roboto;
  font-size: 13px;
  line-height: 14px;
  text-align: center;
  text-align-last: center;
  min-width: 62px;
  width: max-content;
  max-width: 180px;
  background: #ffffff url("../images/select.svg") no-repeat;
  background-size: contain;
  background-position: right 0 center;
  box-shadow: inset -1px 1px 2px rgba(0, 0, 0, 0.1);
  outline: none;
  border-radius: 0;
  appearance: none;
  padding: 0 14px 0 4px;
  border: 1px solid #AAA;
}
.fastInsert {
  position: absolute;
  top: 9px;
  left: 642px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 126px;
  cursor: pointer;
  user-select: none;
  background: #d1dae4;
  border-radius: 3px;
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.05em;
  color: #000000;
}

.fastInsert:hover {
  background: #bcc5d4;
}

.imageGallery {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.imageGallery > div:first-child {
  height: 95%;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageGallery div > div {
  z-index:99;
  position: fixed;
  top: 9px;
  right: 9px;
  cursor: pointer;
}

.imageGallery img {
  width:auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.arrow{
  position: fixed;
  opacity: 0.2;
  width:50px;
  height:100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  transition: 0.2s ease-in-out;
}
.arrow:hover{
  background:rgba(0, 0, 0, 0.1);
  opacity: 1;
}
.nextImg{
  right:0px;
}

.prevImg{
  left:0px;
}

.imagesContainer {
  margin-top: 4px;
  flex: 1;
  overflow-y: auto;
  background-color: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

}

.imageContainer:not(:first-child) {
  margin-top: 11px;
}

.imagesContainerOn {
  margin-top: 12px;
  flex: 1;
  overflow-y: auto;
  background-color: #f4f4f4 !important;
}

.imageContainerOn:not(:first-child) {
  margin-top: 11px;
}

#selecterImg {
  display: none;
}

.mainImagesContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.imageContainer {
  width: auto;
  max-width: 151px;
  position: relative;
  background-color: none;
  cursor: pointer;
  margin-right: 10px;
}

.imageContainer:hover div {
  visibility: visible;
}

.imageContainer img {
  width: auto;
  max-width: inherit;
}

.imageContainer div {
  position: absolute;
  right: 0px;
  margin: 6px 6px;
  cursor: pointer;
  visibility: hidden;
}

.imagesContainerOn img {
  opacity: 0.5;
}

.mainTextColumn{
  padding-bottom: 10px;
  overflow: hidden;
  margin: 16px 0px 0px 20px;
}

#reportTextArea {
  font-family: Roboto;
  min-height: 500px;
  font-size: 15px;
}

.reportSentenceGeneratedText {
  margin-top: 10px;
  padding-top: 4px;
  font-size: 12px;
  border-top: solid 1px #ddd;
  color: #114386;
}

.tipForImageArea{
  margin: 24px 0 10px 3px;
  white-space: normal;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.05em;
  color: #5C5C5C;
}
.centeredText {
  text-align: center;
}