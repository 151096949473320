.popupBody{

}

.popupBody span{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
}

.popupBody input{
    margin-top: 10px !important;
    margin-bottom: 20px;
    width: 80%;
    font-size: 14px !important;
}

.buttonContainer{
    margin-top: 0px;
}

.buttonContainer div{
    font-size: 14px;
}