.authBody {
  height: 100%;
  display: grid;
  grid-template-rows: minmax(0,1fr) minmax(0,max-content) minmax(0,1fr);
  grid-template-columns: 4fr 2fr 4fr;
  background: linear-gradient(#0e314d, #4a6f8c) !important;
}

.authLogo {
  margin-top: 130px;
  width: 200px;
  height: 200px;
  grid-column-start: 2;
  grid-row-start: 1;
  align-self: center;
  justify-self: center;
}

.authLogo img {
  width: 100%;
}

.authContainer{
  margin-top: 90px;
  grid-column-start: 2;
  grid-row-start: 2;
  align-self: center;
}
.authBody > span {
  justify-self: center;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
}
a {
  cursor: pointer;
  font-size: 13px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #f3e9ec;
}
.customButton{
  background: #0c293e;
  outline: none;
  border: none;
  height: 33px;
  width: 100%;
  border-radius: 4px;
  font-size: 15px;
  line-height: 18px;
  color: #F0F0F0;
}
.customButton:active{
  background: #C8C9C9;
}

/* Стили для полей ввода */
.customInput{
  width: 100%;
  outline: none;
  border: 1px solid #A6BDCA;
  border-radius: 4px;
  height: 33px;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 18px;
}
.customInput:focus{
  border-color: rgb(121, 161, 184) !important;
}
.customInput::placeholder{
  color: #B7B7B7;
}
