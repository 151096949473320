/* Общий контейнер для выбора категории */
.categorySelectorContainer{
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(107, 107, 107, 0.1);
  border-radius: 3px;
  display: grid;
  grid-template-rows: [topHeader] minmax(18px,18px) [categoryList] minmax(0,1fr);
}

/* Контейнер для категорий */
.categoryList{
  overflow-y: hidden;
  overflow-x: auto;
  height: auto;
  padding: 10px 0 14px 0;
  display: flex;
  justify-content: flex-start;
}
.categoryList >div{
  display: flex;
  flex-flow: row nowrap;
}
.nodeContainer {
  display: flex;
  overflow-y: auto;
  padding-right: 5px;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-items: stretch;
  width: max-content;
}

.nodeContainer > div {
  position: relative;
  flex: 1 1 auto;
  height: 100px;
  min-height: 0px;
  display: flex;
  flex-flow: column nowrap;
  width: max-content;
}
/* Оформление столбца нодов с картинками */
.mainNode{
  overflow-y: auto;
  min-width:87px;
  max-width: 130px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.mainNode > div{
  margin: 0 10px 10px 10px;
  max-height: 90px;
  min-height: 70px;
  min-width: 80px;
  max-width: 80px;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-self: center;
}
.mainCategory > div{
  display: flex;
  justify-content: center;
}
.mainCategory > div:nth-child(1){
  position: relative;
  align-items: flex-end;
  flex:50;
}
.mainCategory > div:nth-child(2){
  flex:18;
}
.mainCategoryImg {
  height:40px;
}
.mainCategory span{
  text-overflow: ellipsis;
  align-self: center;
  font-size: 11px;
  text-align: center;
  letter-spacing: 0.0357143em;
  color: #4F4F4F;
}
.mainCategory:hover{
  background: rgba(89, 114, 148, 0.12);
  border-radius: 3px;
}

/* Оформление нодов без картинок */
.otherNodes{
  overflow-y: auto;
  box-sizing:border-box;
  width: max-content;
  max-width: 430px;
  display: flex;
  flex-flow: column nowrap;
  user-select: none;
  padding: 2px 11px 0 4px;
  border-left: 0.5px solid #CDCDCD;
}
.otherNodes > div{
  min-height: 19px;
  display: flex;
  align-items: center;
}
.otherNodes div:hover{
  background: rgba(89, 114, 148, 0.12);
  border-radius: 3px;
}
.otherNodes span{
  display:block;
  width: 100%;
  margin: 1px 11px 1px 20px;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space:nowrap;
}

.nodeNameWithTemplate {
  margin: 1px 11px 1px 4px !important;
}

.nodeLeaf {
  font-style: italic;
}

.hasTemplatesInside {
  width: 12px;
  padding-left: 4px;
}

.hasTemplatesInsideBaseNode {
  width: 12px;
  padding-left: 10px;
}

.nodeSelected{
  background: rgba(89, 114, 148, 0.12);
  border-radius: 3px;
}