.reportContainer:hover, .reportContainerSelect{
  background-color: rgba(89, 114, 148, 0.12);
}
.leftMenuContainer{
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(143, 143, 143, 0.1);
  border-radius: 3px;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}  

/* Область для вывода табов */
.leftMenuTabs{
  flex:28 28 37.5px;
  max-height: 37.5px;
  min-height: 37.5px;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-flow: row nowrap;
}
.leftMenuTabs > div{
  flex:1;
  display: flex;
  flex-flow: column nowrap;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.0357143em;
  border-left: 0.5px solid #d4dbe2;
  border-bottom: 0.5px solid #d4dbe2;
}
.leftMenuTabs > div:nth-child(1){
  border-left:0 !important;
}
.leftMenuTabs > div > div{
  flex:3;
}
.tabsSelected{
  border-bottom:0.5px solid #FFFFFF !important;
}
.tabsSelected div{
  background-color: #afc0d0;
}

.tabDisabled {
  color: #777;
}

.leftMenuTabs span{
  flex:25;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Область для поиска заключения */
.leftMenuSearchPanel {
  flex:48 48 72px;
  max-height: 72px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftMenuSearchPanel > div{
  width: 87%;
  height: 46%;
  display: flex;
  border: 2px solid #597094;
  box-sizing: border-box;
}
.leftMenuSearchPanel > div >div:nth-child(1){
  flex: 199;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftMenuSearchPanel > div >div:nth-child(1) input{
  font-size: 13px;
  flex: 1;
  margin: 8px 5px 8px 5px;
  outline: none;
  border: 0;
}
.leftMenuSearchPanel > div >div:nth-child(2){
  flex: 27;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftMenuSearchPanel svg {
  width: 62%;
  height: 62%;
}

/* Область для создания нового заключения */
.leftMenuCreateReports{
  flex:28 28 37.5px;
  max-height: 37.5px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.leftMenuCreateReports svg{
  margin-left: 7px;
  height: 50%;
}
.leftMenuCreateReports span{
  font-size: 12px;
  margin-left: 3px;
  margin-top: 3px;
  line-height: 12px;
}
.leftMenuCreateReports div{
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  width: 89%;
  border-radius: 3px;
}
.leftMenuCreateReports div:hover{
  background-color: #395173 !important;
}
.leftMenuCreateReportsSelected{
  background-color: #395173 !important;
}

.newReportContainer:hover .DoRicon{
  opacity: 1;
}
.DoRicon{
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  position: absolute;
  justify-self: flex-end;
  align-self: center;
  margin-right: 12px;
  z-index:9;
  height: 14px;
}

.leftMenuReports{
  flex: 1 1 396px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.leftMenuReports > div{
  width: 90%;
  /* display: flex;  fix for Safari browser */
  flex-flow: column nowrap;
  cursor: pointer;
}
.newTextContainer img{
  height: 14px;
}

/* Область с кнопками Созданные/Удаленные */
.leftMenuCoD{
  flex:45 45 67.5px;
  max-height: 67.5px;
  min-height: 55px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.leftMenuCoD > div{
  cursor: pointer;
  user-select: none;
  width:75%;
  height:51%; 
  display: flex;
}
.CoD{
  flex:1 1 34px;
  min-height: 34px;
  display: flex;
  justify-content: center;
  border:0.5px solid #D9D9D9;
  box-sizing:content-box;
}
.CoDunselected{
  background-color: #E8E8E8;
  border:0.5px solid #E8E8E8 !important;
}
.CoDleft{
  border-right: 0 !important;
}
.CoDright{
  border-left: 0 !important;
}
.leftMenuCoD span{
  align-self: center;
  vertical-align: middle;
  font-size: 12px;
  color: #000000;
}

#new_report_button {
  background: #587194;
  color: #fff;
  padding-bottom: 1px;
}

#new_report_button_text {
  font-size: 13px;
  letter-spacing: .02em;
}