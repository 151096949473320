.FastInsertPreloader{
  flex:1;
  height: 50px;
  width: 50px;
}
/* Затенение области компонента быстрая вставка и его позиционирование */
.fastInsertBody{
  position: absolute; top:-50px; left:0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  height:108%;
  width:100%;
  /* min-width: 1162px;
  min-height: 200px; */
  overflow: auto;
}

/* Непосредственно сам контейнер быстрой вставки */
.fastInsertContainer{
  display: grid;
  grid-template-rows: [topHeader] 30px [mainBody] minmax(0,1fr);
  width: 95%;
  /* min-width: 1065px;
  min-height: 513px; */
  height: 88%;
  background: #3f4f6a;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(143, 143, 143, 0.1);
  border-radius: 3px;
  overflow: hidden;
  z-index: 2;
}

/* Оформление заголовка + наплыв */
.fastInsertContainer > div:nth-child(1){
  overflow: hidden; border-radius: 3px;
  height: 300px;
}

.fastInsertContainer > div:nth-child(1) span{
  margin-left: auto;
  font-family: Roboto;
  align-self: center;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: #fff;
}
.fastInsertContainer > div:nth-child(1) > div{
  top:0;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fastInsertContainer > div:nth-child(1) > div > div{
  margin-left: auto;
  margin-right: 7px;
  height: 19px;
}

/*  */
.insertArea{
  background: #3f4f6a;
  padding: 14px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(143, 143, 143, 0.1);
  border-radius: 3px;
  overflow: hidden;
  display: flex;
}
.insertArea > div:not(:first-child){
  flex:346;
  display: flex;
  flex-flow: column nowrap;
}
.insertArea > div:not(:first-child){
  margin-left: 16px;
}
.textColumn > div {
  flex:1;
  overflow-y: auto;
  white-space: pre-wrap;
  outline: none;
  resize: none;
  justify-content: stretch;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 9px;
  padding: 9px;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  background: #fff;
}

.textColumn > textarea {
  font-family: Roboto;
  flex:1;
  overflow-y: auto;
  white-space: pre-wrap;
  outline: none;
  resize: none;
  justify-content: stretch;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 9px;
  padding: 9px;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

/* Левое  меню */
.customLeftMenu{
  margin-top: 21px;
  min-width: 250px;
  flex:259;
  max-width: 260px;
  display: flex;
  flex-flow: column nowrap;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
}
.customLeftMenu > div:nth-child(1){
  flex:28;
}
.customLeftMenu > div:nth-child(2){
  flex:60;
  max-height: 60px;
}

.newreportsContainer div img{
  height: 12px;
}

.newreportsContainer{
  flex:396;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.newreportsContainer > div{
  width: 90%;
  display: flex;
  flex-flow: column nowrap;
  
}
.newReportContainer {
  user-select: none;
  border-radius: 3px;
  margin-top: 7px;
  transition: background-color 0.2s ease-in-out;
}
.newReportContainer:hover {
  background-color: rgba(89, 114, 148, 0.12);
}
.newTextContainer{
  min-height: 45px;
  position: relative;
  padding: 7px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}
.newTextContainer > div{
  display: flex;
  align-items: center;
  justify-content: stretch;
  overflow: hidden;
}
.newTextContainer > div:nth-child(1) span{
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 10px;
  color: #7B7B7B;
}

.newTextContainer > div:nth-child(2) span{
  font-size: 13px;
  line-height: 13px;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.newTextContainer > div:nth-child(3) span{
  margin-left: 3px;
  font-size: 11px;
  color: #7B7B7B;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}
.highlightText{
  background: #D5EDFF;
}

.blackBackground{
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1;
}