.topPanelMainContainer{
  margin-top: 8px;
  height: 24px;
  display: flex;
  flex-direction: row;
  z-index: 99;
}

.leftSubPanel {
    justify-content: left;
}

.centerSubPanel {
    justify-content: center;
}

.rightSubPanel {
justify-content: right;
    margin-right: 6px;
}

.leftSubPanel, .centerSubPanel, .rightSubPanel{
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.leftSubPanel span{ 
  vertical-align: middle;
}

.leftSubPanel_logo{
  padding: 1px 0 0 11px;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.leftSubPanel_logo img{
  width: 34px;
  height: 34px;
}

.centerSubPanel_logoText{
  display: flex;
  align-items: center;
  font-size: 16px;
  letter-spacing: 0.2em;
  color: #fff;
}

.rightSubPanel_loginText{
  user-select: none;
  font-size: 14px;
  line-height: 13px;
  flex: 16;
  text-align: right;
  color: #fff;
  align-items: center;
  display: flex;
  flex-flow: row-reverse;
  margin-right: 10px;
}

.rightSubPanel_people{
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 7px;
}

 
.rightSubPanel svg{
  /*height: 57%;
  width: 55%;*/
  cursor: pointer;
} 
.rigthSubpanel_support{
  margin-right: 7px;
  margin-left: 7px;
  margin-top: 1px;
  align-items: center;
  display: flex;
}

body{
  padding: 0px; 
  margin: 0px;
  display: flex;
  flex-flow: column;
  justify-content: stretch;
}