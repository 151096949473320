.settingsPanel{
  margin: 18px;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(107, 107, 107, 0.1);
  border-radius: 3px;
  display: flex;
  flex-flow: column nowrap;
  }
 .settingsPanel > div:nth-child(1){
  flex: 1 1 31px;
  max-height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 0.5px solid #C2C2C2;
  border-radius: 3px 3px 0px 0px;
 }
 
 .settingsPanel > div:nth-child(2){
  flex: 1;
  border: 0.5px solid #D0D0D0;
  border-top:0;
  border-bottom: 0;
 }
 
 .settingsPanel > div:nth-child(3){
  flex: 1 1 31px;
  max-height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #D0D0D0;
  border-top:0;
  border-radius: 0px 0px 3px 3px;
 }
 .settingsPanel > div:nth-child(3) div{
  display: flex;
  justify-content: space-around;
  width:20%;
 }
 .settingsPanel > div:nth-child(3) span{
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  text-decoration-line: underline;
  color: #456883;
 }
 .settingsPanel > div:nth-child(3) span:last-child{
  color: #B34242;
 }
.settingsControlContainer{
  padding: 20px;
  display: flex;
}
.paymentPanel{
  margin-left: 60px;
}
.settingsTabsPanel{
  display: flex;
  flex-flow: column nowrap;
  width: 330px;
  margin-top: 13px;
  height: 200px;
  overflow: hidden;
  border: 0.5px solid #C2C2C2;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(143, 143, 143, 0.1);
  border-radius: 3px
}
.settingsTabsPanel > div:nth-child(2){
  flex: 1;
  display: flex;
  align-items: stretch;
  align-self: stretch;
  justify-self: stretch;
}
.settingsTabsPanel textarea{
  padding: 15px;
  width:100%;
  outline: none;
  overflow-y: auto;
  overflow-x: hidden;
  border:none;
  resize: none;
  font-size: 13px;
  line-height: 12px;
  color: #000000;
}

.infoHeader{
  display: flex;
  padding-top:13px;
  flex-flow: row nowrap;
}
.infoHeader > div{
  margin-left: 20px;
}
.infoHeader > div:nth-child(1){
  flex:1;
  max-width: 330px;
}
.infoHeader > div:nth-child(2){
  flex:1;
  max-width: 330px;
}

.paymentHead{
  display: flex;
  flex-flow: row nowrap;
  margin-top: 10px;
}

.paymentStatus, .paymentDescription, .paymentLinkText{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
}

.paymentStatus{
  line-height: 19px;
  color: #000000;
  height: 19px;
  margin-left: 4px;
}

.paymentDescription{
  font-size: 12px;
  line-height: 11px;
  color: #666666;
  margin-top: 5px;
}

.paymentLinkText{
  line-height: 12px;
  text-decoration: underline;
  color: #456883;
  margin-top: 10px;
  cursor: pointer;
}