.popupContainer div.body div.popupBody{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.0357143em;
    margin: 6% 10% 4% 10%;
    width: 383px;
  }
  
  .popupContainer div.body div.popupBody div.informationText{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
  }
  
  .popupContainer div.body div.popupBody input{
    border-width: 0px 0px 1px 0px;
    font-size: 10px;
    line-height: 12px;
    margin-top: 4px;
    color: #000000;
    outline: none;
  }
  
  .popupContainer div.body div.popupBody input.small{
    width: 40px;
  }

  div.cardData{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  div.cardData div{
    font-size: 8px;
    line-height: 9px;
    flex: 1;
    color: #828282;
    margin-left: 30px;
  }
  
  .buttonContainer{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    width: 260px;
    margin: 0 auto;
    margin-top: 27px;
  }
  
  .buttonContainer div{
    flex: 1;
    font-size: 12px;
    font-family: Roboto;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.0357143em;
    text-align: left;
    color: #1D1D1D;
    cursor: pointer;
  }
  
  .buttonContainer div:last-child{
    font-family: Roboto;
    font-weight: bold;
    color: #3A3A3A;
    flex: 2;
    text-align: right;
    cursor: pointer;
  }

  .saveAndSend{
    width: 240px;
    margin: 0 auto;
  }

  .saveAndSend div span{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.0357143em;
    color: #828282;
  }

  .saveAndSend div input{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 16px;
    letter-spacing: 0.0357143em;
    width: 240px;
    margin-top: 15px;
  }