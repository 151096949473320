 /* @import url('https://fonts.googleapis.com/css?family=Roboto'); */
 
body {
  margin: 0;
  padding: 0;
  font-family:"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",  "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: -webkit-gradient(linear,left top, left bottom,from(#40506b),to(#2d384a));
  background: -webkit-linear-gradient(#40506b,#2d384a);
  background: linear-gradient(#40506b,#2d384a);
  color: #161d27;
}

@font-face {
  font-family: Roboto;
  src: url(../../static/media/Roboto.c613ca81.ttf);
  font-style: normal;
  font-weight: normal;
  line-height: normal;
}

body, html, #root { 
  min-width: 900px;
  min-height: 200px;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*Шрифты выданные заказчиком*/
.restoreText, .unknownError, #mailError, .mailInput, .bottomLink button, .buttonGroup + div span button, .medicalReports, .restoreLink{
  font-family: Roboto;
  
}

/*Основное наименование*/
.medicalReports{
  font-weight: 300;
  font-size: 36px;
  letter-spacing: 0.5em;
  color: #3A566D;
  line-height: 850%;
}

.inputGroup{
  margin-top:13px;
  height: auto;
  width: 100%;
  padding-bottom: 13px;
}

.dataInput{
  margin-top: 13px;
  border-radius: 4px;
  
}

.inputGroup + div span{
  background: #4A6F8C;
  border-radius: 4px;
  
}

.inputGroup + div span:active{
  background: #C8C9C9;  
}

.inputGroup + div span button{
  background-image: none !important;
  width: 250px;
  font-size: 15px;
  color: #F0F0F0;
}

label{
  /* border: 1px solid #A6BDCA !important; Здесь происходить наслоение границ */
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

.mainBlock span{
  text-align: center;
}


/*Ссылки: Регистрация, восстановление пароля*/
.bottomLink button{
  font-size: 13px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #4A6F8C;
  margin: 30px 5px 0px 5px !important;
}
.bottomLink {
  margin-top: 30px;
  height: auto;
  display: flex;
  justify-content: space-around;
}
/*Ситуационный текст*/
.mailInput{
  font-weight: 300;
  font-size: 12px;
  color: #000000;
}

.restoreLink{
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.0357143em;
  color: #456883;
}

.restoreText
{
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #f5f5f5;
}

.tooltip {
  opacity: 1 !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 600px;
  padding: 40px;
  background: #fafafa !important;
  color: #000 !important;
  border: 1px solid #ddd !important;
}

/* Оформление скролов */
::-webkit-scrollbar{
  width: 5px;
  height: 5px;
  border-radius: 99px;
  background-color: #F6F6F6;
}
  
::-webkit-scrollbar-thumb{
  border-radius: 99px;
  background-color: #B7B7B7;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.workSpace{
   height: 100%;
   overflow: hidden;
   display: grid;
   grid-template-rows: minmax(32px,32px) minmax(0,20fr);
   grid-template-columns: 1fr;
}

.containerForPopup{
  position: absolute;
  bottom: 0px; left: 0px; top: 0px; right: 0px;
  width: 1px;;
  height: 1px;
  margin: auto;
}
/* Стиль контейнера редактора репорта */
.reportEditorContainer {
  box-sizing: border-box;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(107, 107, 107, 0.1);
  border-radius: 3px;
  overflow-y: scroll;
  display: grid;
  grid-template-rows:
    [topHeader] minmax(20px, 20px) [reportDataHeader] minmax(32px, -webkit-max-content)
    [categoryList] minmax(0, 1fr);
  grid-template-rows:
    [topHeader] minmax(20px, 20px) [reportDataHeader] minmax(32px, max-content)
    [categoryList] minmax(0, 1fr);
}

/* Заголовок */
.topHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dde5ea;
  border-bottom: 1px solid #d8d8d8;
}
.topHeader span {
  /*color: #fff;*/
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.03em;
}
/* Для работы с данными репорта (дата, фио и т.п. */
.reportDataHeader {
  padding: 10px 0 12px 0;
  overflow-x: auto;
  background: #f0f4f7;
  border-bottom: 1px solid #e3e3e3;
  display: flex;
}
.reportDataHeader > div{
  flex:1 1;
}
.reportDataHeader > div:not(:first-child){
  margin-left: 35px;
}
.reportDataHeader > div:first-child{
  margin-left: 20px;
}
.reportDataHeader > div:nth-child(odd){
  max-width: 120px;
}
.reportDataHeader > div:nth-child(even){
  max-width: 230px;
}
/*  */
.editorArea {
  background: #ecf1f5;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 17px 0 17px 0;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: [controlPanel] minmax(0,1fr) [others] 0px;
}

/* Стили области управления редактором  */
.controlPanel {
  border-left: 0.5px solid #cdcdcd;
  display: flex;
  flex-flow: column nowrap;
  /* display: grid;
  grid-template-rows: [openall] max-content [selectorarea] minmax(0, 1fr) [buttons] 80px; */
}

.controlPanel > div:nth-child(1) {
  flex:1 1 10px;
  max-height: 10px;
  display: flex;
  padding: 0 0 10px 18px;
  align-items: center;
}

.customCheckbox {
  /* Стили для чекбокса */
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.0357143em;
}

.customCheckbox:checked + label {
  color: green !important;
}
.box {
  width: 20px;
  height: 20px;
  content: "";
  background-color: rebeccapurple;
}
.customCheckbox input:checked + box {
  width: 20px;
  height: 20px;
  content: "ddd";
  background-color: green;
}

/* Стили блока селектора */
.blockContainer{
  overflow-y: auto;
  flex:4 4 40px;
  min-height: 30px;
}
.blockSelector {
  padding: 5px 0 5px 0;
  margin: 0 12px 0 12px;
  align-items: stretch;
  display: flex;
  justify-items: stretch;
  flex-flow: column nowrap;
  border-radius: 3px;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

.blockSelector > div {
  border-radius: 3px;
  position: relative;
  justify-content: center;
  min-height: 20px;
  height: 24px;
  margin: 1px 0 0 0;
  padding: 2px 0px 2px 8px;
  flex-flow: column nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}
.blockSelector > div:last-child div {
  position: absolute;
  top: -6px;
  right: 0;
  justify-self: flex-start !important;
  width: 98%;
  border-top: 1px solid #d1d1d1;
}
.blockSelector > div:last-child {
  margin-top: 12px;
}
.blockSelector span {
  align-items: center;
  width: 100%;
  align-self: center !important;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.0357143em;
  color: #000000;
}
.activeCategory {
  background-color: rgba(89, 114, 148, 0.12);
  border-radius: 3px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

/* Блок с кнопками */
.buttonsBlock {
  flex:1 1 106px;
  max-height: 150px;
  max-width: 240px;
  padding: 38px 6px 0 14px;
  display: grid;
  grid-template-rows: repeat(auto-fill, -webkit-max-content);
  grid-template-rows: repeat(auto-fill, max-content);
}
.buttonsBlock .disableButton {
  background-color: none !important; 
  cursor: default;
}
.buttonsBlock > div {
  min-height: 38px;
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  padding-left: 10px;
}

.buttonsBlock > div:nth-child(1) {
  background: #d1dae4;
  border-radius: 3px;
}

.buttonsBlock > div:hover {
  background-color: #bcc5d4;
  border-radius: 3px;
}
.buttonsBlock > div > div:nth-child(1) {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonsBlock > div > div:nth-child(2) {
  flex: 9 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.buttonsBlock span {
  justify-self: flex-start;
  font-size: 13px;
  line-height: 15px;
  padding-left: 6px;
  color: #000000;
}

.customCheckbox {
  position: absolute;
  opacity: 0;
}

.customCheckbox + span {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  cursor: pointer;
  font-size: 13px;
  /*letter-spacing: 0.0357143em;*/
  color: #000000;
}

.customCheckbox + span:before {
  content: url(../../static/media/checkbox.13916ac6.svg);
  width: 13px;
  vertical-align: middle;
  height: 14px;
  box-sizing: border-box;
  margin-right: 7px;
  display: inline-block;
}

.customCheckbox:checked + span:before {
  content: url(../../static/media/checkboxSelected.3facf031.svg);
}
.test {
  background: #fff;
  border-radius: 4px;
  display: flex;
  padding: 20px 0px 0 15px;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-items: stretch;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  width: 99%;
  margin: 0px 0px 14px 0;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.test > div {
  position: relative;
  flex: 1 1 auto;
  margin-top: 14px;
  min-height: 0px;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 6px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.objectsContainer > form > label {
  padding: 2px 0 0 0px;
}

.paragraphSeparator {
height: 2px;
width: 100px;
background: #aaa;
}

.simpleTextArea {
  margin-top: 20px !important;
  background: #fff;
}

.test textarea, .simpleTextArea {
  outline: none;
  white-space: pre-wrap;
  margin-top: 9px;
  padding: 9px;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 300px;
  max-height: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  min-width: 400px;
  width: 750px;
  resize: both;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.reportTextContainer {
  background: #eaeef2;
  border-radius: 4px;
  max-width: 1020px;
}

.reportTextContainer > div {
  margin-top: 0 !important;
}

.categoryHeader {
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #464646;
}

.categoryHeaderFastInsert {
  color: #fff;
}

.categoryHeaderForReport {
  border-left: none;
  padding: 4px !important;
  font-size: 23px;
  letter-spacing: 0.03em;
  }

.reportTextHeader {
  position: absolute;
  left: 334px;
  top: 13px;
}

.editorParts {
  margin: 0 17px 0 17px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  width: auto;
  display: flex;
  align-items: stretch;
  /*overflow-x: auto;*/
}

.editorParts > div {
  display: flex;
  flex-flow: column nowrap;
  padding: 0 0 14px 0;
  width: 100%;
}

.imageUploadButtons {
  margin-top: 20px;
  height: 27px;
  width: 151px;
  background: #f4f4f4;
  border-radius: 3px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.imageUploadButtons:hover {
  background-color: rgba(89, 114, 148, 0.12);
}
.imageUploadButtons svg {
  flex: 1 1;
}
.imageUploadButtons span {
  flex: 4 1;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: #000000;
}

.imagesContainer {
  margin-top: 12px;
  padding-right: 5px;
}

.imageContainer:not(:first-child) {
  margin-top: 11px;
}

#selecterImg {
  display: none;
}

.mainImagesContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.imageContainer {
  position: relative;
  background-color: none;
  cursor: pointer;
}

.imageContainer div {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  right: 0px;
  margin: 6px 6px;
  cursor: pointer;
}
.imageContainer:hover div {
  opacity: 1;
}

.subColumn > span {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 700;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0.0357143em;
  color: #5d6c73;
}

.subColumn {
  margin: 0px 14px 18px 0px;
  padding: 4px 14px 10px 12px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  width: 180px;
  min-width: 100px;
  border: 2px solid #ececec;
  border-radius: 4px;
  background: #fefefe;
}

.subColumn p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.0357143em;
}

form {
  display: flex;
  flex-flow: column nowrap;
}
.customRadio {
  display: none;
}
.customRadio + span {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  /*letter-spacing: 0.0357143em;*/
}
.customRadio + span:before {
  content: url(../../static/media/radiobutton.7e675386.svg);
  width: 14px;
  vertical-align: middle;
  height: 14px;
  box-sizing: border-box;
  margin-right: 7px;
  display: inline-block;
}
.customRadio:checked + span:before {
  content: url(../../static/media/radiobuttonselected.659a1d19.svg);
}

.inpText span {
  margin-top: 8px;
  font-size: 13px;
  line-height: 11px;
  text-align: center;
  /*letter-spacing: 0.0357143em;*/
}
.inpText input {
  text-align: center;
  margin: 0 5px 0 5px;
  outline: none;
  width: 40px;
  min-width: 40px;
  max-width: 350px;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border: 0.5px solid #bbbbbb;
  background: none;
}
.AoDButton {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid;
  border-right: 4px solid;
  height: 22px;
  width: 80px;
  box-sizing: border-box;
  border-radius: 1px;
  text-align: center;
  line-height: 11px;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}

.AoDButton span:nth-child(1) {
  flex: 3 1;
  font-size: 11px;
}
.AoDButton span::first-letter {
  font-weight: 900;
}
.AoDButton.Add {
  margin-top: 10px;
  color: #6fcf97;
  border-color: #6fcf97;
}
.AoDButton.Add:hover {
  color: #fff;
  background-color: #6fcf97;
}
.AoDButton.Delete:hover {
  color: #fff;
  background-color: #eb5757;
}

.AoDButton.Delete {
  margin-top: 7px;
  margin-bottom: 9px;
  color: #eb5757;
  border-color: #eb5757;
}
.objectsContainer {
  margin-left: 0px;
  margin-top: 6px;
}
.objectsContainer form:not(:first-child) {
  margin-top: 10px;
}
.bottomBorder {
  border-bottom: 0.5px solid #e8e8e8;
}

.customSelect select {
  font-family: Roboto;
  font-size: 13px;
  line-height: 14px;
  text-align: center;
  -moz-text-align-last: center;
       text-align-last: center;
  min-width: 62px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 180px;
  background: #ffffff url(../../static/media/select.90760af7.svg) no-repeat;
  background-size: contain;
  background-position: right 0 center;
  box-shadow: inset -1px 1px 2px rgba(0, 0, 0, 0.1);
  outline: none;
  border-radius: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0 14px 0 4px;
  border: 1px solid #AAA;
}
.fastInsert {
  position: absolute;
  top: 9px;
  left: 642px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 126px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: #d1dae4;
  border-radius: 3px;
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.05em;
  color: #000000;
}

.fastInsert:hover {
  background: #bcc5d4;
}

.imageGallery {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.imageGallery > div:first-child {
  height: 95%;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageGallery div > div {
  z-index:99;
  position: fixed;
  top: 9px;
  right: 9px;
  cursor: pointer;
}

.imageGallery img {
  width:auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.arrow{
  position: fixed;
  opacity: 0.2;
  width:50px;
  height:100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.arrow:hover{
  background:rgba(0, 0, 0, 0.1);
  opacity: 1;
}
.nextImg{
  right:0px;
}

.prevImg{
  left:0px;
}

.imagesContainer {
  margin-top: 4px;
  flex: 1 1;
  overflow-y: auto;
  background-color: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

}

.imageContainer:not(:first-child) {
  margin-top: 11px;
}

.imagesContainerOn {
  margin-top: 12px;
  flex: 1 1;
  overflow-y: auto;
  background-color: #f4f4f4 !important;
}

.imageContainerOn:not(:first-child) {
  margin-top: 11px;
}

#selecterImg {
  display: none;
}

.mainImagesContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.imageContainer {
  width: auto;
  max-width: 151px;
  position: relative;
  background-color: none;
  cursor: pointer;
  margin-right: 10px;
}

.imageContainer:hover div {
  visibility: visible;
}

.imageContainer img {
  width: auto;
  max-width: inherit;
}

.imageContainer div {
  position: absolute;
  right: 0px;
  margin: 6px 6px;
  cursor: pointer;
  visibility: hidden;
}

.imagesContainerOn img {
  opacity: 0.5;
}

.mainTextColumn{
  padding-bottom: 10px;
  overflow: hidden;
  margin: 16px 0px 0px 20px;
}

#reportTextArea {
  font-family: Roboto;
  min-height: 500px;
  font-size: 15px;
}

.reportSentenceGeneratedText {
  margin-top: 10px;
  padding-top: 4px;
  font-size: 12px;
  border-top: solid 1px #ddd;
  color: #114386;
}

.tipForImageArea{
  margin: 24px 0 10px 3px;
  white-space: normal;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.05em;
  color: #5C5C5C;
}
.centeredText {
  text-align: center;
}
.topPanelMainContainer{
  margin-top: 8px;
  height: 24px;
  display: flex;
  flex-direction: row;
  z-index: 99;
}

.leftSubPanel {
    justify-content: left;
}

.centerSubPanel {
    justify-content: center;
}

.rightSubPanel {
justify-content: right;
    margin-right: 6px;
}

.leftSubPanel, .centerSubPanel, .rightSubPanel{
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.leftSubPanel span{ 
  vertical-align: middle;
}

.leftSubPanel_logo{
  padding: 1px 0 0 11px;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.leftSubPanel_logo img{
  width: 34px;
  height: 34px;
}

.centerSubPanel_logoText{
  display: flex;
  align-items: center;
  font-size: 16px;
  letter-spacing: 0.2em;
  color: #fff;
}

.rightSubPanel_loginText{
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 14px;
  line-height: 13px;
  flex: 16 1;
  text-align: right;
  color: #fff;
  align-items: center;
  display: flex;
  flex-flow: row-reverse;
  margin-right: 10px;
}

.rightSubPanel_people{
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 7px;
}

 
.rightSubPanel svg{
  /*height: 57%;
  width: 55%;*/
  cursor: pointer;
} 
.rigthSubpanel_support{
  margin-right: 7px;
  margin-left: 7px;
  margin-top: 1px;
  align-items: center;
  display: flex;
}

body{
  padding: 0px; 
  margin: 0px;
  display: flex;
  flex-flow: column;
  justify-content: stretch;
}
.workPanel{
  position: relative;
  display: grid;
  justify-items: stretch;
  grid-gap: 10px 10px;
  grid-template-columns: [leftMenu] minmax(240px,240px) [rightPanel] minmax(0,1fr)/* 3.239fr */;
  padding: 10px 8px 8px;
}

.messageText{
  justify-self: center;
  align-self: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #a7a7a7;
}

.modWorkPanel{
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  color: #A6A6A6;
}

.modWorkPanel span{
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #456883;
  cursor: pointer;
}

.mainPanelHelp {
  align-self: center;
  background-image: url("/img/mainPanelHelp.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}
.reportContainer:hover, .reportContainerSelect{
  background-color: rgba(89, 114, 148, 0.12);
}
.leftMenuContainer{
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(143, 143, 143, 0.1);
  border-radius: 3px;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}  

/* Область для вывода табов */
.leftMenuTabs{
  flex:28 28 37.5px;
  max-height: 37.5px;
  min-height: 37.5px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  flex-flow: row nowrap;
}
.leftMenuTabs > div{
  flex:1 1;
  display: flex;
  flex-flow: column nowrap;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.0357143em;
  border-left: 0.5px solid #d4dbe2;
  border-bottom: 0.5px solid #d4dbe2;
}
.leftMenuTabs > div:nth-child(1){
  border-left:0 !important;
}
.leftMenuTabs > div > div{
  flex:3 1;
}
.tabsSelected{
  border-bottom:0.5px solid #FFFFFF !important;
}
.tabsSelected div{
  background-color: #afc0d0;
}

.tabDisabled {
  color: #777;
}

.leftMenuTabs span{
  flex:25 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Область для поиска заключения */
.leftMenuSearchPanel {
  flex:48 48 72px;
  max-height: 72px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftMenuSearchPanel > div{
  width: 87%;
  height: 46%;
  display: flex;
  border: 2px solid #597094;
  box-sizing: border-box;
}
.leftMenuSearchPanel > div >div:nth-child(1){
  flex: 199 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftMenuSearchPanel > div >div:nth-child(1) input{
  font-size: 13px;
  flex: 1 1;
  margin: 8px 5px 8px 5px;
  outline: none;
  border: 0;
}
.leftMenuSearchPanel > div >div:nth-child(2){
  flex: 27 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftMenuSearchPanel svg {
  width: 62%;
  height: 62%;
}

/* Область для создания нового заключения */
.leftMenuCreateReports{
  flex:28 28 37.5px;
  max-height: 37.5px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.leftMenuCreateReports svg{
  margin-left: 7px;
  height: 50%;
}
.leftMenuCreateReports span{
  font-size: 12px;
  margin-left: 3px;
  margin-top: 3px;
  line-height: 12px;
}
.leftMenuCreateReports div{
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  align-items: center;
  width: 89%;
  border-radius: 3px;
}
.leftMenuCreateReports div:hover{
  background-color: #395173 !important;
}
.leftMenuCreateReportsSelected{
  background-color: #395173 !important;
}

.newReportContainer:hover .DoRicon{
  opacity: 1;
}
.DoRicon{
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  position: absolute;
  justify-self: flex-end;
  align-self: center;
  margin-right: 12px;
  z-index:9;
  height: 14px;
}

.leftMenuReports{
  flex: 1 1 396px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.leftMenuReports > div{
  width: 90%;
  /* display: flex;  fix for Safari browser */
  flex-flow: column nowrap;
  cursor: pointer;
}
.newTextContainer img{
  height: 14px;
}

/* Область с кнопками Созданные/Удаленные */
.leftMenuCoD{
  flex:45 45 67.5px;
  max-height: 67.5px;
  min-height: 55px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.leftMenuCoD > div{
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width:75%;
  height:51%; 
  display: flex;
}
.CoD{
  flex:1 1 34px;
  min-height: 34px;
  display: flex;
  justify-content: center;
  border:0.5px solid #D9D9D9;
  box-sizing:content-box;
}
.CoDunselected{
  background-color: #E8E8E8;
  border:0.5px solid #E8E8E8 !important;
}
.CoDleft{
  border-right: 0 !important;
}
.CoDright{
  border-left: 0 !important;
}
.leftMenuCoD span{
  align-self: center;
  vertical-align: middle;
  font-size: 12px;
  color: #000000;
}

#new_report_button {
  background: #587194;
  color: #fff;
  padding-bottom: 1px;
}

#new_report_button_text {
  font-size: 13px;
  letter-spacing: .02em;
}
.current{
  background-color: rgba(89, 114, 148, 0.12);
}

.notCurrent{
  background-color: #ffffff;
}

.brainImg{
  float: left;
  height: 20px;
  line-height: 20px;
}

.brainText{
  margin-left: 4px;
  height: 20px;
  line-height: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  color: #7B7B7B;
  float: left;
}

.options{
  float: left;
  width: 10%;
  height: 100%;
  min-height: 38px;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.options div:first-child{
  flex: 3 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.options div:last-child{
  flex: 1 1;
  width: 8px;
}

.experimental{
  height: 1px;
  background-color:rgba(89, 114, 148, 0.12);;
  margin: 4px 8px 0px 8px;
}

.notFoundText {
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #929292;
  width: 200px;
  margin: 0 auto;
  margin-top: 20px;
}

.noReportsText{
  font-size: 13px;
  text-align: left;
}

.reportsLoader{
  flex:1 1;
  height: 75px;
}
.newReportContainer{
  -webkit-animation: opacity 300ms ease-in-out;
          animation: opacity 300ms ease-in-out;
}

@-webkit-keyframes opacity {
  from { opacity : 0; }
  to { opacity :1; }
}

@keyframes opacity {
  from { opacity : 0; }
  to { opacity :1; }
}
/* Общий контейнер для выбора категории */
.categorySelectorContainer{
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(107, 107, 107, 0.1);
  border-radius: 3px;
  display: grid;
  grid-template-rows: [topHeader] minmax(18px,18px) [categoryList] minmax(0,1fr);
}

/* Контейнер для категорий */
.categoryList{
  overflow-y: hidden;
  overflow-x: auto;
  height: auto;
  padding: 10px 0 14px 0;
  display: flex;
  justify-content: flex-start;
}
.categoryList >div{
  display: flex;
  flex-flow: row nowrap;
}
.nodeContainer {
  display: flex;
  overflow-y: auto;
  padding-right: 5px;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-items: stretch;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.nodeContainer > div {
  position: relative;
  flex: 1 1 auto;
  height: 100px;
  min-height: 0px;
  display: flex;
  flex-flow: column nowrap;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
/* Оформление столбца нодов с картинками */
.mainNode{
  overflow-y: auto;
  min-width:87px;
  max-width: 130px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.mainNode > div{
  margin: 0 10px 10px 10px;
  max-height: 90px;
  min-height: 70px;
  min-width: 80px;
  max-width: 80px;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-self: center;
}
.mainCategory > div{
  display: flex;
  justify-content: center;
}
.mainCategory > div:nth-child(1){
  position: relative;
  align-items: flex-end;
  flex:50 1;
}
.mainCategory > div:nth-child(2){
  flex:18 1;
}
.mainCategoryImg {
  height:40px;
}
.mainCategory span{
  text-overflow: ellipsis;
  align-self: center;
  font-size: 11px;
  text-align: center;
  letter-spacing: 0.0357143em;
  color: #4F4F4F;
}
.mainCategory:hover{
  background: rgba(89, 114, 148, 0.12);
  border-radius: 3px;
}

/* Оформление нодов без картинок */
.otherNodes{
  overflow-y: auto;
  box-sizing:border-box;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 430px;
  display: flex;
  flex-flow: column nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 2px 11px 0 4px;
  border-left: 0.5px solid #CDCDCD;
}
.otherNodes > div{
  min-height: 19px;
  display: flex;
  align-items: center;
}
.otherNodes div:hover{
  background: rgba(89, 114, 148, 0.12);
  border-radius: 3px;
}
.otherNodes span{
  display:block;
  width: 100%;
  margin: 1px 11px 1px 20px;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space:nowrap;
}

.nodeNameWithTemplate {
  margin: 1px 11px 1px 4px !important;
}

.nodeLeaf {
  font-style: italic;
}

.hasTemplatesInside {
  width: 12px;
  padding-left: 4px;
}

.hasTemplatesInsideBaseNode {
  width: 12px;
  padding-left: 10px;
}

.nodeSelected{
  background: rgba(89, 114, 148, 0.12);
  border-radius: 3px;
}
  .loader {
    margin: 0 auto;
    margin-top: 20px;
    display: none;
  }
  .dot {
    width: 12px;
    height: 12px;
    background: #3ac;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: slide 1s infinite;
            animation: slide 1s infinite;
  }
  .dot:nth-child(1) {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
    background: #334C60;
  }
  .dot:nth-child(2) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
    background: #334C60;
  }
  .dot:nth-child(3) {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
    background: #334C60;
  }
  .dot:nth-child(4) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
    background: #334C60;
  }
  .dot:nth-child(5) {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    background: #334C60;
  }
  @-webkit-keyframes slide {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      opacity: 0.3;
      -webkit-transform: scale(2);
              transform: scale(2);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes slide {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      opacity: 0.3;
      -webkit-transform: scale(2);
              transform: scale(2);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .preloaderText{
      font-family: Roboto;
      text-align: center;
      color: rgb(28, 37, 43);
  }
.textInput span{
   font-family: Roboto;
   font-size: 10px;
   line-height: 9px;
   letter-spacing: 0.0357143em;
   color: #828282;
   -webkit-user-select: none;
      -moz-user-select: none;
       -ms-user-select: none;
           user-select: none;
}
.textInput input {
   margin-left: 0px;
   border:0;
   font-family: Roboto;
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   line-height: 12px;
   letter-spacing: 0.0357143em;
   color: #000000;
   background: none;
}

.nonEditableInput {
   pointer-events: none;
}

.noBorder {
   border: none !important;
}

input[type="date"]{
  width: 130px;
}
.textInput input:hover{
   outline: none;
   background: rgba(89, 114, 148, 0.12);
}
.textInput input:focus{
   outline: none;
}
.textInput {
   display: flex;
   flex-direction: column;
   border-bottom: 0.5px solid #dadada;
}
.textInput *{
   margin:2px;
}
.textInput input:disabled{
   color: #6D6D6D !important;
   -webkit-user-select: none !important;
      -moz-user-select: none !important;
       -ms-user-select: none !important;
           user-select: none !important;
   background: #FFFFFF;
}
.FastInsertPreloader{
  flex:1 1;
  height: 50px;
  width: 50px;
}
/* Затенение области компонента быстрая вставка и его позиционирование */
.fastInsertBody{
  position: absolute; top:-50px; left:0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  height:108%;
  width:100%;
  /* min-width: 1162px;
  min-height: 200px; */
  overflow: auto;
}

/* Непосредственно сам контейнер быстрой вставки */
.fastInsertContainer{
  display: grid;
  grid-template-rows: [topHeader] 30px [mainBody] minmax(0,1fr);
  width: 95%;
  /* min-width: 1065px;
  min-height: 513px; */
  height: 88%;
  background: #3f4f6a;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(143, 143, 143, 0.1);
  border-radius: 3px;
  overflow: hidden;
  z-index: 2;
}

/* Оформление заголовка + наплыв */
.fastInsertContainer > div:nth-child(1){
  overflow: hidden; border-radius: 3px;
  height: 300px;
}

.fastInsertContainer > div:nth-child(1) span{
  margin-left: auto;
  font-family: Roboto;
  align-self: center;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: #fff;
}
.fastInsertContainer > div:nth-child(1) > div{
  top:0;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fastInsertContainer > div:nth-child(1) > div > div{
  margin-left: auto;
  margin-right: 7px;
  height: 19px;
}

/*  */
.insertArea{
  background: #3f4f6a;
  padding: 14px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(143, 143, 143, 0.1);
  border-radius: 3px;
  overflow: hidden;
  display: flex;
}
.insertArea > div:not(:first-child){
  flex:346 1;
  display: flex;
  flex-flow: column nowrap;
}
.insertArea > div:not(:first-child){
  margin-left: 16px;
}
.textColumn > div {
  flex:1 1;
  overflow-y: auto;
  white-space: pre-wrap;
  outline: none;
  resize: none;
  justify-content: stretch;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 9px;
  padding: 9px;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  background: #fff;
}

.textColumn > textarea {
  font-family: Roboto;
  flex:1 1;
  overflow-y: auto;
  white-space: pre-wrap;
  outline: none;
  resize: none;
  justify-content: stretch;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 9px;
  padding: 9px;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

/* Левое  меню */
.customLeftMenu{
  margin-top: 21px;
  min-width: 250px;
  flex:259 1;
  max-width: 260px;
  display: flex;
  flex-flow: column nowrap;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
}
.customLeftMenu > div:nth-child(1){
  flex:28 1;
}
.customLeftMenu > div:nth-child(2){
  flex:60 1;
  max-height: 60px;
}

.newreportsContainer div img{
  height: 12px;
}

.newreportsContainer{
  flex:396 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.newreportsContainer > div{
  width: 90%;
  display: flex;
  flex-flow: column nowrap;
  
}
.newReportContainer {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 3px;
  margin-top: 7px;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}
.newReportContainer:hover {
  background-color: rgba(89, 114, 148, 0.12);
}
.newTextContainer{
  min-height: 45px;
  position: relative;
  padding: 7px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}
.newTextContainer > div{
  display: flex;
  align-items: center;
  justify-content: stretch;
  overflow: hidden;
}
.newTextContainer > div:nth-child(1) span{
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 10px;
  color: #7B7B7B;
}

.newTextContainer > div:nth-child(2) span{
  font-size: 13px;
  line-height: 13px;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.newTextContainer > div:nth-child(3) span{
  margin-left: 3px;
  font-size: 11px;
  color: #7B7B7B;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}
.highlightText{
  background: #D5EDFF;
}

.blackBackground{
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.settingsPanel{
  margin: 18px;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(107, 107, 107, 0.1);
  border-radius: 3px;
  display: flex;
  flex-flow: column nowrap;
  }
 .settingsPanel > div:nth-child(1){
  flex: 1 1 31px;
  max-height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 0.5px solid #C2C2C2;
  border-radius: 3px 3px 0px 0px;
 }
 
 .settingsPanel > div:nth-child(2){
  flex: 1 1;
  border: 0.5px solid #D0D0D0;
  border-top:0;
  border-bottom: 0;
 }
 
 .settingsPanel > div:nth-child(3){
  flex: 1 1 31px;
  max-height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #D0D0D0;
  border-top:0;
  border-radius: 0px 0px 3px 3px;
 }
 .settingsPanel > div:nth-child(3) div{
  display: flex;
  justify-content: space-around;
  width:20%;
 }
 .settingsPanel > div:nth-child(3) span{
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #456883;
 }
 .settingsPanel > div:nth-child(3) span:last-child{
  color: #B34242;
 }
.settingsControlContainer{
  padding: 20px;
  display: flex;
}
.paymentPanel{
  margin-left: 60px;
}
.settingsTabsPanel{
  display: flex;
  flex-flow: column nowrap;
  width: 330px;
  margin-top: 13px;
  height: 200px;
  overflow: hidden;
  border: 0.5px solid #C2C2C2;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(143, 143, 143, 0.1);
  border-radius: 3px
}
.settingsTabsPanel > div:nth-child(2){
  flex: 1 1;
  display: flex;
  align-items: stretch;
  align-self: stretch;
  justify-self: stretch;
}
.settingsTabsPanel textarea{
  padding: 15px;
  width:100%;
  outline: none;
  overflow-y: auto;
  overflow-x: hidden;
  border:none;
  resize: none;
  font-size: 13px;
  line-height: 12px;
  color: #000000;
}

.infoHeader{
  display: flex;
  padding-top:13px;
  flex-flow: row nowrap;
}
.infoHeader > div{
  margin-left: 20px;
}
.infoHeader > div:nth-child(1){
  flex:1 1;
  max-width: 330px;
}
.infoHeader > div:nth-child(2){
  flex:1 1;
  max-width: 330px;
}

.paymentHead{
  display: flex;
  flex-flow: row nowrap;
  margin-top: 10px;
}

.paymentStatus, .paymentDescription, .paymentLinkText{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
}

.paymentStatus{
  line-height: 19px;
  color: #000000;
  height: 19px;
  margin-left: 4px;
}

.paymentDescription{
  font-size: 12px;
  line-height: 11px;
  color: #666666;
  margin-top: 5px;
}

.paymentLinkText{
  line-height: 12px;
  text-decoration: underline;
  color: #456883;
  margin-top: 10px;
  cursor: pointer;
}
.popupContainer {
  position: absolute;
  min-width: 232px;
  min-height: 100px;
  background-color: #ffffff;
  border-radius: 5px 5px 5px 5px;
  border: 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 3px 11px 22px rgba(59, 59, 59, 0.25);
  z-index: 101;
}

.popupContainer div.head {
  width: 100%;
  height: 6px;
  border-radius: 5px 5px 0px 0px;
}

.popupContainer div.body {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-width: 0px;
  border-bottom-width: 0px;
}

.popupContainer div.bottom {
  height: 6px;
  border-radius: 0px 0px 5px 5px;
  border-top-width: 0px;
}

#bgPopup{
  width: 100%;
  height: 100%;
  background-color: #597294;
  opacity: 0.7;
  position: absolute;
  left: 0px;
  top: 0px;
}

.containerForPopup{
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popupContainer div.body div.popupBody{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.0357143em;
    margin: 6% 10% 4% 10%;
    width: 383px;
  }
  
  .popupContainer div.body div.popupBody div.informationText{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
  }
  
  .popupContainer div.body div.popupBody input{
    border-width: 0px 0px 1px 0px;
    font-size: 10px;
    line-height: 12px;
    margin-top: 4px;
    color: #000000;
    outline: none;
  }
  
  .popupContainer div.body div.popupBody input.small{
    width: 40px;
  }

  div.cardData{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  div.cardData div{
    font-size: 8px;
    line-height: 9px;
    flex: 1 1;
    color: #828282;
    margin-left: 30px;
  }
  
  .buttonContainer{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    width: 260px;
    margin: 0 auto;
    margin-top: 27px;
  }
  
  .buttonContainer div{
    flex: 1 1;
    font-size: 12px;
    font-family: Roboto;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.0357143em;
    text-align: left;
    color: #1D1D1D;
    cursor: pointer;
  }
  
  .buttonContainer div:last-child{
    font-family: Roboto;
    font-weight: bold;
    color: #3A3A3A;
    flex: 2 1;
    text-align: right;
    cursor: pointer;
  }

  .saveAndSend{
    width: 240px;
    margin: 0 auto;
  }

  .saveAndSend div span{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.0357143em;
    color: #828282;
  }

  .saveAndSend div input{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 16px;
    letter-spacing: 0.0357143em;
    width: 240px;
    margin-top: 15px;
  }
.popupBody{

}

.popupBody span{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
}

.popupBody input{
    margin-top: 10px !important;
    margin-bottom: 20px;
    width: 80%;
    font-size: 14px !important;
}

.buttonContainer{
    margin-top: 0px;
}

.buttonContainer div{
    font-size: 14px;
}
.C_SaP, .C_Ns_S, .C_G_S, .C_D, .C_Subs, .C_E, .C_Unsub, .O, .C_G_Cont{
  display: flex;
  flex-direction: row;
}

.C_G_S{
  margin-left: -30px;
  margin-right: -30px;
}

.C_E{
  margin: 0 0%;
}

.C_E div+div{
  color: #466289 !important;

}

.O div{
  font-weight: bold;
}

.C_SaP div, .C_G_S div, .C_D div, .C_Subs div, .C_E div, .C_Unsub div, .O div, .C_G_Cont div, .C_Ns_S div{
  margin-top: 20px;
  flex: 1 1;
  font-size: 12px;
  font-family: Roboto;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.0357143em;
  text-align: center;
  color: #1D1D1D;
  cursor: pointer;
}

.C_Ns_S div, .C_Ns_S div:last-child, .C_G_S div:last-child, .C_G_Cont div:last-child{
  margin-top: 20px;
  flex: 1 1;
  font-size: 12px;
  font-family: Roboto;
  line-height: 14px;
  letter-spacing: 0.0357143em;
  color: #1D1D1D;
}

.C_Ns_S div + div, .C_G_S div + div, .C_Unsub div:last-child, .C_G_Cont div + div{
  font-weight: bold;
  color: #A52F2F;
}

.C_G_S div{
  flex: 0.5 1;
}

.C_G_S div + div{
  flex: 1.5 1;
}

.C_Subs div:last-child, .C_E div:last-child{
  font-weight: bold;
  color: #3B4F6A;
}

.C_D div:last-child{
  color: #A52F2F;
  font-weight: bold;
}

.C_G_Cont div:first-child{
  text-align: left;
}

.C_G_Cont div:last-child{
  text-align: right;
}

.C_G_Cont div + div{
  margin-left: -20px;
  text-align: left;
}
.authBody {
  height: 100%;
  display: grid;
  grid-template-rows: minmax(0,1fr) minmax(0,-webkit-max-content) minmax(0,1fr);
  grid-template-rows: minmax(0,1fr) minmax(0,max-content) minmax(0,1fr);
  grid-template-columns: 4fr 2fr 4fr;
  background: -webkit-gradient(linear, left top, left bottom, from(#0e314d), to(#4a6f8c)) !important;
  background: -webkit-linear-gradient(#0e314d, #4a6f8c) !important;
  background: linear-gradient(#0e314d, #4a6f8c) !important;
}

.authLogo {
  margin-top: 130px;
  width: 200px;
  height: 200px;
  grid-column-start: 2;
  grid-row-start: 1;
  align-self: center;
  justify-self: center;
}

.authLogo img {
  width: 100%;
}

.authContainer{
  margin-top: 90px;
  grid-column-start: 2;
  grid-row-start: 2;
  align-self: center;
}
.authBody > span {
  justify-self: center;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
}
a {
  cursor: pointer;
  font-size: 13px;
  line-height: 15px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #f3e9ec;
}
.customButton{
  background: #0c293e;
  outline: none;
  border: none;
  height: 33px;
  width: 100%;
  border-radius: 4px;
  font-size: 15px;
  line-height: 18px;
  color: #F0F0F0;
}
.customButton:active{
  background: #C8C9C9;
}

/* Стили для полей ввода */
.customInput{
  width: 100%;
  outline: none;
  border: 1px solid #A6BDCA;
  border-radius: 4px;
  height: 33px;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 18px;
}
.customInput:focus{
  border-color: rgb(121, 161, 184) !important;
}
.customInput::-webkit-input-placeholder{
  color: #B7B7B7;
}
.customInput::-moz-placeholder{
  color: #B7B7B7;
}
.customInput::-ms-input-placeholder{
  color: #B7B7B7;
}
.customInput::placeholder{
  color: #B7B7B7;
}

.smallError {
  width: auto;
  float: left;
  position: absolute;
  font-size: 13px;
  color: #BD3131;
  /* text-align: left; */
  line-height: 34px;
  margin-left: 17px;
  white-space: nowrap;
}

.smallErrorAuth {
  color: #ff4343 !important;
}

.unknownError{
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3 !important;
  display: block;
  margin-top: 44px;
  font-size: 18px;
  line-height: 21px;
  color: #ff4343;
}
.successType{
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3 !important;
  display: block;
  margin-top: 44px;
  font-size: 18px;
  line-height: 21px;
  color: rgb(255, 255, 255);
}
