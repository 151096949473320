.textInput span{
   font-family: Roboto;
   font-size: 10px;
   line-height: 9px;
   letter-spacing: 0.0357143em;
   color: #828282;
   user-select: none;
}
.textInput input {
   margin-left: 0px;
   border:0;
   font-family: Roboto;
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   line-height: 12px;
   letter-spacing: 0.0357143em;
   color: #000000;
   background: none;
}

.nonEditableInput {
   pointer-events: none;
}

.noBorder {
   border: none !important;
}

input[type="date"]{
  width: 130px;
}
.textInput input:hover{
   outline: none;
   background: rgba(89, 114, 148, 0.12);
}
.textInput input:focus{
   outline: none;
}
.textInput {
   display: flex;
   flex-direction: column;
   border-bottom: 0.5px solid #dadada;
}
.textInput *{
   margin:2px;
}
.textInput input:disabled{
   color: #6D6D6D !important;
   user-select: none !important;
   background: #FFFFFF;
}