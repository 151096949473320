 /* @import url('https://fonts.googleapis.com/css?family=Roboto'); */
 
body {
  margin: 0;
  padding: 0;
  font-family:"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",  "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(#40506b,#2d384a);
  color: #161d27;
}

@font-face {
  font-family: Roboto;
  src: url(./fonts/Roboto.ttf);
  font-style: normal;
  font-weight: normal;
  line-height: normal;
}

body, html, #root { 
  min-width: 900px;
  min-height: 200px;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*Шрифты выданные заказчиком*/
.restoreText, .unknownError, #mailError, .mailInput, .bottomLink button, .buttonGroup + div span button, .medicalReports, .restoreLink{
  font-family: Roboto;
  
}

/*Основное наименование*/
.medicalReports{
  font-weight: 300;
  font-size: 36px;
  letter-spacing: 0.5em;
  color: #3A566D;
  line-height: 850%;
}

.inputGroup{
  margin-top:13px;
  height: auto;
  width: 100%;
  padding-bottom: 13px;
}

.dataInput{
  margin-top: 13px;
  border-radius: 4px;
  
}

.inputGroup + div span{
  background: #4A6F8C;
  border-radius: 4px;
  
}

.inputGroup + div span:active{
  background: #C8C9C9;  
}

.inputGroup + div span button{
  background-image: none !important;
  width: 250px;
  font-size: 15px;
  color: #F0F0F0;
}

label{
  /* border: 1px solid #A6BDCA !important; Здесь происходить наслоение границ */
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

.mainBlock span{
  text-align: center;
}


/*Ссылки: Регистрация, восстановление пароля*/
.bottomLink button{
  font-size: 13px;
  text-decoration-line: underline;
  color: #4A6F8C;
  margin: 30px 5px 0px 5px !important;
}
.bottomLink {
  margin-top: 30px;
  height: auto;
  display: flex;
  justify-content: space-around;
}
/*Ситуационный текст*/
.mailInput{
  font-weight: 300;
  font-size: 12px;
  color: #000000;
}

.restoreLink{
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.0357143em;
  color: #456883;
}

.restoreText
{
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #f5f5f5;
}

.tooltip {
  opacity: 1 !important;
  width: fit-content;
  max-width: 600px;
  padding: 40px;
  background: #fafafa !important;
  color: #000 !important;
  border: 1px solid #ddd !important;
}

/* Оформление скролов */
::-webkit-scrollbar{
  width: 5px;
  height: 5px;
  border-radius: 99px;
  background-color: #F6F6F6;
}
  
::-webkit-scrollbar-thumb{
  border-radius: 99px;
  background-color: #B7B7B7;
}